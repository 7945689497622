<template>
  <div :class="`modal modal-view${_class ? ' ' + _class : ''} ${modalClass}`" v-if="modal" :id="`modal-${this.modalID}`">
    <div class="modal-content">
      <div class="modal-overlay" v-if="modalSettings.outClose" @click="close"></div>
      <div class="modal-card" :noclose="modalSettings.noclose">
        <div class="modal-card-header" v-if="modalSettings.header !== false">
          <div class="modal-card-header-left">
            <slot name="header"></slot>
          </div>
          <div class="modal-card-header-right" v-if="!modalSettings.noclose">
            <slot name="header-right"></slot>
            <a class="button" @click="close"><iconic name="times" /></a>
          </div>
        </div>
        <div class="modal-card-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["_class", "settings"],
  data: function() {
    return {
      modal: false,
      modalID: this.$global.fastID(),
      modalClass: "",
    };
  },
  methods: {
    elements: function(el) {
      const elmns = {
        collapse: document.querySelector(`#modal-${this.modalID}`),
        card: document.querySelector(`#modal-${this.modalID} .modal-card`),
        custom: document.querySelector(el),
      };
      return elmns[el] || elmns["custom"];
    },
    open: function() {
      this.modal = true;
      this.elements("body").classList.add("onmodal");
      this.$emit("open");
    },
    close: async function() {
      this.modalClass = "closing";

      if (this.modalSettings.closeDelay) {
        await this.sleep(this.modalSettings.closeDelay);
      }

      this.modal = false;
      this.elements("body").classList.remove("onmodal");
      this.$emit("close");
    },
  },
  computed: {
    modalSettings: function() {
      return { ...this.settings };
    },
  },
  destroyed: function() {
    const countModals = document.querySelectorAll(".modal-view");
    if (countModals.length < 1) {
      this.elements("body").classList.remove("onmodal");
    }
  },
  mounted: function() {
    this.$emit("mounted");
    if (this.modalSettings.opened) {
      this.open();
    }
  },
};
</script>

<style lang="scss" scoped>
.modal {
  @include Fixed();
  z-index: 999;
  &-logo {
    color: $primary-color;
    font-size: 200%;
    margin: $mpadding/2 0 $mpadding $mpadding;
  }
  &-content {
    @include Fixed();
    display: grid;
    padding: $mpadding;
    background-color: var(--bg-overlay);
    overflow-y: auto;
  }
  &-overlay {
    @include Fixed();
    z-index: -1;
  }
  &-card {
    background-color: #fff;
    border-radius: $mradius;
    width: 100%;
    max-width: 280px;
    margin: auto;
    &-header {
      @include Flex(inherit, space-between, inherit);
      .button {
        @include Flex(inherit, center, center);
        padding: $mpadding;
        color: $sec_color;
        cursor: pointer;
        &:active {
          @include d-active();
        }
        &:hover {
          color: $primary_color;
        }
      }
      &-right {
        display: flex;
        align-items: flex-start;
      }
    }
    &-content {
      padding: 0 $mpadding $mpadding $mpadding;
    }
  }
  &.mk_head .modal-card-header {
    border-bottom: solid 1px $alto;
  }
  &.mobile-s {
    .modal-card {
      max-width: $mobile_s_width;
    }
  }
  &.mobile-m {
    .modal-card {
      max-width: $mobile_m_width;
    }
  }
  &[lock],
  &[lockapp] {
    * {
      pointer-events: none;
    }
  }
}
</style>
